* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  list-style-type: none;
  font-family: "Ubuntu", "Poppins", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-tap-highlight-color: transparent;
}

#root {
  background-color: #fff;
  width: 100%;
  height: 100vh;
}
